.Header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  max-width: 8000px;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.image {
  height: 2rem;
}
.Nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.MenuBurger {
  display: none;
  z-index: 100;
}
.Icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.Nav1 {
  display: flex;
  z-index: 100;
  align-items: flex-start;
  flex-direction: column;
}
.Container {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.MenuClose {
  z-index: 100;
}
.icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  z-index: 100;
}






@media(max-width: 991px) {
  .Icon {
    display: flex;
  }
}
@media(max-width: 767px) {
  .Header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .Nav {
    display: none;
  }
  .MenuBurger {
    display: flex;
  }
  .Icon {
    fill: #ffffff;
    z-index: 100;
  }
  .MenuMobile {
    display: none;
    background-color: var(--dl-color-gray-white);
  }
  .MenuClose {
    background-color: #ffffff;
  }
}
@media(max-width: 479px) {
  .Header {
    padding: var(--dl-space-space-unit);
  }
  .Icon {
    fill: #ffffff;
    z-index: 100;
  }
}
