.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.container1 {
  display: flex;
  position: relative;
}
.text {
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-threeunits);
}
