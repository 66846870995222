.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.container1 {
  display: flex;
  position: relative;
  align-self: stretch;
}
.container2 {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.Heading {
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-threeunits);
}
.text01 {
  color: #000000;
  padding: var(--dl-space-space-threeunits);
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-unit);
  background-color: #ffffff;
}
