.container {
  width: 100%;
  display: flex;
  z-index: 100;
  overflow: auto;
  min-height: 100vh;
  align-items: stretch;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url('https://images.unsplash.com/photo-1626379961798-54f819ee896a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIyNHx8dmlydHVhbHxlbnwwfHx8fDE2NDE0MjM0MzA&ixlib=rb-1.2.1&w=1500');
}
.Gallery {
  display: grid;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-unit);
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr;
}
.GalleryCard1 {
  text-decoration: none;
}
.GalleryCard2 {
  text-decoration: none;
}
@media(max-width: 991px) {
  .Gallery {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .Gallery {
    display: flex;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .Gallery {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
}
