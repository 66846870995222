.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.container01 {
  display: flex;
  position: relative;
}
.container02 {
  display: flex;
  position: relative;
}
.Heading {
  font-style: normal;
  font-weight: 400;
  margin-left: var(--dl-space-space-threeunits);
}
.container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.form {
  flex: 1;
  align-self: center;
}
.container04 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.container05 {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
}
.container06 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.container07 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.text01 {
  margin-right: var(--dl-space-space-unit);
}
.textinput {
  color: #ffffff;
  border-color: var(--dl-color-gray-500);
  border-radius: 0px;
  background-color: #464646;
}
.container08 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.textinput1 {
  color: #ffffff;
  border-color: var(--dl-color-gray-500);
  border-radius: 0px;
  background-color: #4a4a4a;
}
.container09 {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.textarea {
  color: #ffffff;
  height: 100%;
  align-self: center;
  border-color: var(--dl-color-gray-500);
  border-radius: 0px;
  background-color: #464646;
}
.container10 {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.container11 {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
}
.checkbox {
  align-self: flex-start;
  margin-right: var(--dl-space-space-unit);
}
.text04 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
}
.button {
  color: var(--dl-color-gray-white);
  align-self: flex-start;
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  list-style-type: disc;
  background-color: var(--dl-color-button-background);
  list-style-image: none;
  list-style-position: outside;
}
.button:hover {
  background-color: var(--dl-color-button-hoverbackground);
}
.button:active {
  background-color: var(--dl-color-button-active);
}
@media(max-width: 767px) {
  .form {
    margin-bottom: var(--dl-space-space-unit);
  }
  .container05 {
    flex-direction: column;
  }
  .container09 {
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 479px) {
  .form {
    margin-bottom: var(--dl-space-space-unit);
  }
  .container09 {
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .textarea {
    width: 100%;
  }
}
