.Footer {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 8000px;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: space-between;
  border-color: #984226;
  border-width: 0px;
  flex-direction: row;
  border-top-width: 5px;
}
.container1 {
  width: 100%;
  height: var(--dl-size-size-medium);
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #ffffff;
}
.text {
  color: #000000;
  font-size: 24px;
  align-self: flex-start;
  text-align: left;
}
.container2 {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
}
.text1 {
  color: #000000;
  width: 100%;
  font-size: 11px;
  align-self: center;
}
.navlink {
  color: #000000;
  width: 100%;
  align-self: center;
  text-align: center;
}
.text2 {
  width: 100%;
}
.image {
  width: 100px;
  object-fit: cover;
}
.rootClassName {
  flex: 1;
  background-color: #3d3535;
}
.rootClassName1 {
  flex: 1;
}
.rootClassName2 {
  flex: 1;
}
.rootClassName3 {
  flex: 1;
}
.rootClassName4 {
  flex: 1;
}
.rootClassName5 {
  align-self: stretch;
}

@media(max-width: 479px) {
  .Footer {
    flex-direction: column;
  }
  .rootClassName5 {
    flex: 0 0 auto;
  }
}
