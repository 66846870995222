.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.container1 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.image {
  width: 100%;
  align-self: center;
  object-fit: cover;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.text {
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
  padding-left: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-unit);
}
.text2 {
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-twounits);
}
