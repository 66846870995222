.GalleryCard {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: center;
  background-color: #464646;
}
.image {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-large);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.container {
  flex: 0 0 auto;
  width: var(--dl-size-size-xlarge);
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.text {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text1 {
  color: #ffffff;
  align-self: flex-start;
}
.button {
  color: var(--dl-color-gray-white);
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  background-color: var(--dl-color-button-background);
}
.button:hover {
  background-color: var(--dl-color-button-hoverbackground);
}
.button:active {
  background-color: var(--dl-color-button-active);
}


@media(max-width: 991px) {
  .GalleryCard {
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .GalleryCard {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .image {
    height: 10rem;
  }
}
