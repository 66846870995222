.GalleryCard {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.image {
  width: var(--dl-size-size-xxlarge);
  height: var(--dl-size-size-xxlarge);
  object-fit: cover;
}
.text {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text1 {
  color: #ffffff;
  align-self: flex-start;
}



@media(max-width: 991px) {
  .GalleryCard {
    align-items: center;
  }
  .image {
    align-self: center;
  }
  .text {
    align-self: center;
  }
  .text1 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .GalleryCard {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .image {
    height: 10rem;
  }
}
