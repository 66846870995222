.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1626379961798-54f819ee896a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIyNHx8dmlydHVhbHxlbnwwfHx8fDE2NDE0MjM0MzA&ixlib=rb-1.2.1&w=1500');
}
.Banner {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  z-index: 100;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.Heading {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
}
.text {
  color: #ffffff;
  max-width: 8000px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.navlink {
  color: #ffffff;
  padding-top: var(--dl-space-space-unit);
  border-color: #ffffff;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navlink:hover {
  transform: scale(1.02);
}
.container1 {
  flex: 1;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.Banner1 {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.text3 {
  color: #ffffff;
}
.container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.form {
  align-items: flex-start;
  flex-direction: column;
}
.container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
}
.button {
  color: var(--dl-color-gray-white);
  font-size: 0.75rem;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  background-color: var(--dl-color-button-background);
}
.button:hover {
  background-color: var(--dl-color-button-hoverbackground);
}
.button:active {
  background-color: var(--dl-color-button-active);
}
.container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text4 {
  font-size: 11px;
}
.text5 {
  color: #ffffff;
}
.text7 {
  color: #ffffff;
}
@media(max-width: 991px) {
  .text {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .Banner {
    z-index: 0;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .text {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .Banner1 {
    z-index: 0;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .Banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .Banner1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .container3 {
    align-items: center;
    flex-direction: column;
  }
  .button {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
