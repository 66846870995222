.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url('https://images.unsplash.com/photo-1626379961798-54f819ee896a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIyNHx8dmlydHVhbHxlbnwwfHx8fDE2NDE0MjM0MzA&ixlib=rb-1.2.1&w=1500');
}
.Banner {
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-self: center;
  align-items: center;
  border-color: #dcdcdc;
  border-style: double;
  border-width: var(--dl-size-size-xsmall);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: space-between;
  background-color: #c9c9c9;
}
.text {
  color: #000000;
  font-size: 3rem;
  font-style: normal;
  text-align: center;
  font-family: Nunito;
  font-weight: 400;
}
.text2 {
  color: #000000;
  max-width: 8000px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.text4 {
  font-size: 2em;
  align-self: stretch;
  font-style: normal;
  font-weight: 400;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #3d3535;
}
.Gallery {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: 8000px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: #3d3535;
  grid-template-columns: 1fr 1fr 1fr;
}
@media(max-width: 991px) {
  .text2 {
    max-width: 100%;
  }
  .Gallery {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .Banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .text2 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .Gallery {
    display: flex;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .Banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .Gallery {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
